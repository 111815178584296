import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';



const landingStyle = makeStyles((theme: Theme) =>
  createStyles({
    parallexContainer: {
      position: "absolute",
      display: 'flex',
      flexDirection: 'column',
      background: `rgba(0, 0, 0, 0.3)`,
      left: "0",
      top: `0`,
      width: '100%',
      //height: 100,
      padding: 5,
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
    },
    parallexInfoContainer: {
      display: 'flex',
      flex: 7,
      flexDirection: 'column',
      justifyContent: 'center'
    },
    parallexActionContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    },
    parallexTitleContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      color: '#ddd'
    },
    parallexTitle: {
      fontSize: 16, 
      fontWeight: 'bold'
    },
    parallexSlideTitleContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center'
    },
    parallexSlideTitle: {
      fontSize: 26, 
      fontWeight: 'bold'
    },
    parallexDescription: {
      display: 'flex',
      flex: 2,
      flexDirection: 'row',
      alignItems: 'center',
      color: '#ddd'
    },
    gridViewThumbnailOverlay: {
      backgroundColor: "#0008",
      minHeight: "50px",
      maxHeight: "100px",
      overflow: "hidden",
      top: "0",
      width: "100%",
      color: "white",
      padding: "4px",
      fontSize: "90%",
      display: 'flex',
      alignItems: 'center'
    },

    imageViewerContent: {
      display: 'flex', 
      justifyContent: 'center', 
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
    imageViewerTransformToolbar: {
      display: 'flex',
      position: 'absolute',
      flexDirection: 'column',
      top: 0,
      left: 0,
      width: '100%',
      background: `rgba(0, 0, 0, 0.5)`,
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
    },
    imageViewerTransformToolbarLeft: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
    imageViewerTransformToolbarCenter: {
      display: 'flex',
      flex: 10,
      flexDirection: 'row',
      justifyContent: 'center'
    },
    imageViewerTransformToolbarButton: {
      padding: 6
    },
    imageViewerTransformToolbarRight: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    imageViewerPhotoDetailContainerExpanded: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
      color: '#ddd'
    },
    imageViewerPhotoDetailContainerCollapsed: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 10,
      paddingRight: 10,
      color: '#ddd'
    },
    imageViewerPhotoProps: {
      marginRight: 10, 
      minWidth: 50
    },

    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },

    sharePopover: {
      backgroundColor: 'transparent !important',
      boxShadow: 'none'
    },
  })
);

export default landingStyle;
