import {actionType} from './actionType';

export function pageHomeSetViewMode(mode) {
  return {
    type: actionType.page.home.setViewMode,
    payload: mode
  }
}

export function pageGalleryUploadSetSelectedFile(file) {
  return {
    type: actionType.page.gallery.upload.setSelectedFile,
    payload: file
  }
}

export function pageGalleryUploadSetForm(form) {
  return {
    type: actionType.page.gallery.upload.setForm,
    payload: form
  }
}

export function pageGalleryUploadSetFormField(field, value) {
  return {
    type: actionType.page.gallery.upload.setFormField,
    payload: {field, value}
  }
}

export function pageGalleryUploadSetFormValidation(formValidation) {
  return {
    type: actionType.page.gallery.upload.setFormValidation,
    payload: formValidation
  }
}

export function pageGalleryUploadSetFormValidationField(field, validation) {
  return {
    type: actionType.page.gallery.upload.setFormValidationField,
    payload: {field, validation}
  }
}