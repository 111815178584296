import * as React from "react";
import { graphql } from 'gatsby';
import Layout from "../components/Layout";
// import Seo from "../components/Seo";
import FieldValue from '../components/FieldValue';
import Image from '../components/Image';
import { Button, IconButton, Zoom, Dialog, DialogContent, 
  DialogTitle, DialogContentText, DialogActions,
  Collapse, TextField, Popover,
  Select, FormControl, FormHelperText, InputLabel, 
  MenuItem, Checkbox, FormControlLabel, Input, InputAdornment, Fab,
  LinearProgress
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as MUIIcons from '@material-ui/icons';
import { Parallax } from "react-parallax";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import useCommonStyles from '../assets/jss/commonStyles';
import useLandingStyles from '../assets/jss/landingStyles';
import useWindowResize from "../components/Hooks/useWindowResize"

import {useTranslation} from 'gatsby-plugin-react-i18next';
import { connect } from 'react-redux';
import { actionType } from '../redux/actions/actionType';
import * as pageActions from '../redux/actions/pageActions';
import * as systemActions from '../redux/actions/systemActions';
import * as galleryActions from '../redux/actions/galleryActions';
import * as cartActions from '../redux/actions/cartActions';

import Helper from '@justnice/helper';
import UI from '../lib/ui';
import Api from '../lib/api';
import {Constant} from '../lib/constant';
import moment from 'moment';
import {
  FacebookShareButton,
  FacebookIcon,
  // PinterestShareButton,
  // PinterestIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import Markdown from 'markdown-to-jsx';

// var qs = require('qs');
import qs from 'qs';
import useIntersection from '../components/Hooks/useIntersection';
import Gallery from 'react-grid-gallery';
import backgroundSlideImage from '../images/slide_bg.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const itemToAddFormIS = {licenseType: '', website: '', coName: '', coRegNum: '', coRegCountry: '', coWebsite: '', ack: ''};
const itemToAddFormValidationIS = {
  licenseType: {error: false, touched: false, message: ''},
  website: {error: false, touched: false, message: ''},
  coName: {error: false, touched: false, message: ''},
  coRegNum: {error: false, touched: false, message: ''},
  coRegCountry: {error: false, touched: false, message: ''},
  coWebsite: {error: false, touched: false, message: ''},
  ack: {error: false, touched: false, message: ''}
};

const IndexPage : React.FC = (props) => {
  const {t} = useTranslation('home');
  const classes = useLandingStyles();
  const commonClasses = useCommonStyles();
  const windowSize = useWindowResize();
  const dialogClasses = makeStyles((theme: Theme) =>
    createStyles({
      imageViewerContainer: {
        minWidth: windowSize.width,
        minHeight: windowSize.height,
        padding: 0,
        margin: 0
      },
      imageViewerTransformContent: {
        width: windowSize.width,
        height: windowSize.height,
        padding: 0,
        margin: 0,
        justifyContent: 'center'
      },
      cartDialogContainer: {
        minWidth: windowSize.width * 0.5,
        minHeight: windowSize.height * 0.7,
        padding: 0,
        margin: 0
      }
    })
  )();
  const topRef = React.useRef();
  const [loadMoreRef, setLoadMoreRef] = React.useState(null);
  const [loadMoreInProgress, setLoadMoreInProgress] = React.useState(false);
  const topRefShowed = useIntersection(topRef, '0px'); 
  const inViewport = useIntersection(loadMoreRef, '0px'); 

  const auth = Helper.getStateAsJSObj(props['session'], 'auth.data', null);
  const isAuth = Helper.isNotNullAndUndefined(auth) && Helper.stringHasValue(auth.jwt) && Helper.isNotNullAndUndefined(auth.user);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [lastSearchKeyword, setLastSearchKeyword] = React.useState('');
  const [selectedImageId, setSelectedImageId] = React.useState('');
  
  const [expandDetail, setExpandDetail] = React.useState(true);
  const [showShareOption, setShowShareOption] = React.useState('');
  const [anchorPopoverEl, setAnchorPopoverEl] = React.useState(null);
  const [itemToAdd, setItemToAdd] = React.useState('');
  const [itemToAddForm, setItemToAddForm] = React.useState(itemToAddFormIS);
  const [itemToAddFormValidation, setItemToAddFormValidation] = React.useState(itemToAddFormValidationIS);
  const itemToAddFormFields = [
    {field: 'licenseType', required: true, regExp: '', type: 'string', label: 'field.label.licenseType'},
    {field: 'website', required: true, regExp: '^(?=^.{1,253}$)(([a-z\d]([a-z\d-]{0,62}[a-z\d])*[\.]){1,3}[a-z]{1,61})$', type: 'string', label: 'field.label.website'},
    {field: 'coName', required: true, regExp: '', type: 'string', label: 'field.label.co.name'},
    {field: 'coRegNum', required: true, regExp: '', type: 'string', label: 'field.label.co.regNum'},
    {field: 'coRegCountry', required: true, regExp: '', type: 'string', label: 'field.label.co.country'},
    {field: 'coWebsite', required: true, regExp: '^(?=^.{1,253}$)(([a-z\d]([a-z\d-]{0,62}[a-z\d])*[\.]){1,3}[a-z]{1,61})$', type: 'string', label: 'field.label.co.website'},
    {field: 'ack', required: true, regExp: '', type: 'string', label: 'field.label.licenseAgreement'}
  ];

  const showCasePhoto = Helper.getStateAsJSObj(props['gallery'], 'showCasePhoto.data.assets', []);
  const showCasePhotoLoading = Helper.getStateAsJSObj(props['gallery'], 'showCasePhoto.loading', false);
  const showCasePaging = {
    page: Helper.getStateAsJSObj(props['gallery'], 'showCasePhoto.data.page', -1),
    page_size: Helper.getStateAsJSObj(props['gallery'], 'showCasePhoto.data.page_size', 50),
    total_page: Helper.getStateAsJSObj(props['gallery'], 'showCasePhoto.data.total_page', 0)
  };
  
  const owner = Helper.getStateAsJSObj(props['session'], 'myProfile.data', null);
  const sysList = Helper.getStateAsJSObj(props['system'], 'sysList.data', []);
  const contentList = Helper.getStateAsJSObj(props['system'], 'content.data', []);
  const licenseTypeContent = contentList.find(item => item.category === 'LICENSE_TYPE' && item.type === itemToAddForm.licenseType);
  const locale = props.ui.get('lang');
  const viewMode = props.page.getIn('home.viewMode'.split('.'));
  const slideList = contentList.filter(item => item.category === 'WEBCONTENT' && item.type === 'LANDING_SLIDE');

  let selectedImage = null;
  if(Helper.stringHasValue(selectedImageId)) {
    selectedImage = showCasePhoto.find(item => item.id === selectedImageId);
  }

  let selectedShareImage = null;
  if(Helper.stringHasValue(showShareOption)) {
    selectedShareImage = showCasePhoto.find(item => item.id === showShareOption);
  }

  let selectedCartImage = null;
  if(Helper.stringHasValue(itemToAdd)) {
    selectedCartImage = showCasePhoto.find(item => item.id === itemToAdd);
  }

  let selectedImageFormat = null;
  if(selectedImage) {
    selectedImageFormat = sysList.find(item => item.category === 'IMAGE_FORMAT' && item.code === selectedImage.image_format);
  }

  let selectedImageMedium = null;
  if(selectedImage) {
    selectedImageMedium = sysList.find(item => item.category === 'IMAGE_MEDIUM' && item.code === selectedImage.image_medium);
  }

  const pageMeta = [];
  if(Helper.isNotNullAndUndefined(selectedImage)) {
    if(Helper.stringHasValue(selectedImage.title)) {
      pageMeta.push({
        name: "title",
        content: `${selectedImage.title} | JustShoot.It`
      });
    }

    if(Helper.stringHasValue(selectedImage.description)) {
      pageMeta.push({
        name: "description",
        content: `${selectedImage.description}`
      });
    }

    if(Helper.arrayHasItem(selectedImage.tag)) {
      pageMeta.push({
        name: "keywords",
        content: selectedImage.tag.join(',')
      });
    }
  }

  const loadShowCasePhotos = (resetPage = false) => {
    if(!resetPage || searchKeyword !== lastSearchKeyword ) {
      setLoadMoreInProgress(true);
      props.dispatch(
        galleryActions.showCasePhotoGetList({keyword: {value: searchKeyword}}, resetPage ? 0 : showCasePaging.page + 1, (success, result) => {
          setLastSearchKeyword(searchKeyword);
          setLoadMoreInProgress(false);
        })
      );
    }
  }

  if (inViewport) {
    if(!loadMoreInProgress && showCasePaging.page < (showCasePaging.total_page - 1)) {
      loadShowCasePhotos();
    }
  }

  const onFormFieldChanged = (field, value) => {
    let form = Helper.isNotNullAndUndefined(itemToAddForm) ? Helper.deepCopy(itemToAddForm) : itemToAddFormIS;
    form[field] = value;
    setItemToAddForm(form);
  }

  const validateFormField = (field, value) => {
    let formField = itemToAddFormFields.find(item => item.field === field);
    let formFieldValidate = {error: false, touched: false, message: ''};
    let localFormValidation = Helper.isNotNullAndUndefined(itemToAddFormValidation) ? Helper.deepCopy(itemToAddFormValidation) : {};
    
    if(Helper.isNotNullAndUndefined(formField)) {
      formFieldValidate.touched = true;

      // If required, check if have value
      if(formField.required && !Helper.stringHasValue(value)) {
        // No value, set error to true and set message
        formFieldValidate.error = true;
        formFieldValidate.message = t('error.message.fieldRequired').replace('{0}', t(formField.label));
      }

      // If field have value and regex is not empty, check the regex
      if(Helper.stringHasValue(formField.regExp) && Helper.stringHasValue(value)) {
        let regExp = RegExp(formField.regExp);
        if(!regExp.test(value)) {
          formFieldValidate.error = true;
          formFieldValidate.message = t('error.message.fieldInvalid').replace('{0}', t(formField.label));
        }
      }
      

      localFormValidation[field] = formFieldValidate;
      setItemToAddFormValidation(localFormValidation);
    }
  }

  const validateForm = (fieldsToValidate = [], form = null) => {
    let fields = Helper.arrayHasItem(fieldsToValidate) ? itemToAddFormFields.filter(item => fieldsToValidate.includes(item.field)) : itemToAddFormFields;
    let validation = Helper.deepCopy(itemToAddFormValidation);
    let localForm = Helper.isNotNullAndUndefined(form) ? form : itemToAddForm;
    let formIsValid = true;

    fields.forEach(item => {
      let skipField = false;
      validation[item.field].error = false;
      validation[item.field].message = '';

      skipField = itemToAddForm.licenseType !== 'WEB' && item.field === 'website';
      switch (itemToAddForm.licenseType) {
        case 'PERSONAL':
          skipField = ['website', 'coName', 'coRegNum', 'coRegCountry', 'coWebsite'].includes(item.field);
          break;

        case 'WEB':
          skipField = ['coName', 'coRegNum', 'coRegCountry', 'coWebsite'].includes(item.field);
          break;

        case 'ADVERTISING':
          skipField = item.field === 'website';
          break;
      }

      if(!skipField) {
        // If required, check if have value
        if(item.required && !Helper.stringHasValue(localForm[item.field])) {
          // No value, set error to true and set message
          validation[item.field].error = true;
          validation[item.field].message = t('error.message.fieldRequired').replace('{0}', t(item.label));
          formIsValid = false;
        }
        
        // If field have value and regex is not empty, check the regex
        if(Helper.stringHasValue(item.regExp) && Helper.stringHasValue(localForm[item.field])) {
          let regExp = RegExp(item.regExp);
          if(!regExp.test(localForm[item.field])) {
            validation[item.field].error = true;
            validation[item.field].message = t('error.message.fieldInvalid').replace('{0}', t(item.label));
            formIsValid = false;
          }
        }
      }
    });

    setItemToAddFormValidation(validation);
    return formIsValid;
  }

  const getItemPriceInt = () => {
    let iPrice = 0;

    if(Helper.stringHasValue(itemToAddForm.licenseType) && Helper.isNotNullAndUndefined(selectedCartImage) && Helper.isNotNullAndUndefined(selectedCartImage.price)) {
      switch(itemToAddForm.licenseType.toUpperCase()) {
        case 'PERSONAL':
          if(Helper.isNotNullAndUndefined(selectedCartImage.price.price1)) {
            iPrice = Number.parseFloat(selectedCartImage.price.price1);
          }
          break;

        case 'WEB':
          if(Helper.isNotNullAndUndefined(selectedCartImage.price.price2)) {
            iPrice = Number.parseFloat(selectedCartImage.price.price2);
          }
          break;

        case 'ADVERTISING':
          if(Helper.isNotNullAndUndefined(selectedCartImage.price.price3)) {
            iPrice = Number.parseFloat(selectedCartImage.price.price3);
          }
          break;
      }
    }

    return iPrice;
  }

  const getItemPrice = () => {
    let price ='';
    let iPrice = getItemPriceInt();

    if(iPrice > 0) {
      price = UI.formatCurrency(iPrice, 2);
    }
    
    return price;
  }

  React.useEffect(() => {
    const tagObj = Helper.getStateAsJSObj(props['system'], 'tag', null);
    const sysListObj = Helper.getStateAsJSObj(props['system'], 'sysList', null);
    const contentObj = Helper.getStateAsJSObj(props['system'], 'content', null);

    // Set the default view mode to GRID if the device with is 500px or less
    props.dispatch(
      pageActions.pageHomeSetViewMode(window && window.innerWidth > 500 ? 'PARALLAX':'GRID')
    );
    
    // If no tag info and tag is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(tagObj) || (!Helper.arrayHasItem(tagObj?.data) && !tagObj?.loading)) {
      props.dispatch(
        systemActions.tagGetList()
      );
    }

    // If no system list info and system list is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(sysListObj) || 
       (!Helper.arrayHasItem(sysListObj?.data) && !sysListObj?.loading) ||
       (sysListObj?.locale !== locale && !sysListObj?.loading)) {
      props.dispatch(
        systemActions.sysListGet(locale)
      );
    }

    // If no content info and content list is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(contentObj) || 
       (!Helper.arrayHasItem(contentObj?.data) && !contentObj?.loading) ||
       (contentObj?.locale !== locale && !contentObj?.loading)) {
      props.dispatch(
        systemActions.contentListGet(locale)
      );
    }

    props.dispatch(
      galleryActions.showCasePhotoGetList({keyword: {value: searchKeyword}}, 0, (success, result) => {
        if(success && Helper.isNotNullAndUndefined(result) && Helper.isNotNullAndUndefined(result.data) && Helper.arrayHasItem(result.data.assets)) {
          if(Helper.stringHasValue(props.location.search)) {
            let queryParam = qs.parse(props.location.search.substr(1));
            if(Helper.isNotNullAndUndefined(queryParam) && Helper.stringHasValue(queryParam.asset)) {
              let asset = result.data.assets.find(item => item.id === queryParam.asset);
              
              if(!Helper.isNotNullAndUndefined(asset)) {
                // If the asset not found, then load it from server
                props.dispatch(
                  galleryActions.showCasePhotoGetItem(queryParam.asset, (success2, result2) => {
                    if(success2) {
                      setSelectedImageId(queryParam.asset)
                      assetViewCountInc(queryParam.asset);
                    }
                  })
                );
              }
              else {
                setSelectedImageId(queryParam.asset)
                assetViewCountInc(queryParam.asset);
              }
            }
          }
        }
      })
    );

    // props.dispatch(
    //   cartActions.cartGetList()
    // );

    
  }, []);
  
  const onCartFormClose = () => {
    setItemToAdd('');
    setItemToAddForm(itemToAddFormIS);
    setItemToAddFormValidation(itemToAddFormValidationIS);
  }

  const onCartFormAdd = () => {
    if(validateForm()) {
      let item = {
        owner: owner?.user?.id,
        asset: itemToAdd,
        license_type: itemToAddForm.licenseType,
        price: getItemPriceInt(),
        license_info: null
      };

      switch (itemToAddForm.licenseType) {
        case 'WEB':
          item.license_info = { website: itemToAddForm.website};
          break;

        case 'ADVERTISING':
          item.license_info = { 
            coName: itemToAddForm.coName,
            coRegNum: itemToAddForm.coRegNum,
            coRegCountry: itemToAddForm.coRegCountry,
            coWebsite: itemToAddForm.coWebsite
          };
          break;
      }

      // If user have not login yet
      if(!isAuth) {
        item.id = `local_${(new Date()).getTime().toString()}`;
        item.asset = showCasePhoto.find(asset => asset.id === itemToAdd);
      }

      props.dispatch(
        isAuth?
        cartActions.cartItemAdd(item, (success, result) => {
          setItemToAdd('');
          setItemToAddForm(itemToAddFormIS);
          setItemToAddFormValidation(itemToAddFormValidationIS);
        })
        :
        {
          type: actionType.cart.cartItemAddFulfilled,
          payload: item
        }
      );
      
      // If user have not login yet
      if(!isAuth) {
        setItemToAdd('');
        setItemToAddForm(itemToAddFormIS);
        setItemToAddFormValidation(itemToAddFormValidationIS);
      }
    }
  }

  const assetViewCountInc = (id) => {
    Api.assetViewCountInc(id).catch(err => { console.error(Helper.getErrorMsg(err)) });
  }

  const populateParallax = () => {
    const parallaxUI = [];

    if(Helper.arrayHasItem(showCasePhoto)) {
      showCasePhoto.map((item, index) => {
        let viewSize = Number.parseInt(process.env.IMAGE_VIEWSIZE);
        let scale = 1;
        let screenScale = 1;
        let insertRef = false;
        if(item.image_size.width > item.image_size.height) {
          scale = viewSize / item.image_size.width;
        }
        else {
          scale = viewSize / item.image_size.height;
        }

        screenScale = windowSize.width / (item.image_size.width * scale)

        // Only insert ref when total page is more then 1, 
        // and current page is not at last page, 
        // and current item is at 70% of the page size
        insertRef = showCasePaging.total_page > 1 
                    && showCasePaging.page < (showCasePaging.total_page - 1)
                    && Math.floor(showCasePaging.page_size * (showCasePaging.page + 1) - 2) === (index + 1);
        
        parallaxUI.push(
          <Parallax 
            key={item.id}
            bgImage={`${process.env.GATSBY_API_URL}/assets/view/${item._id}?token=${owner ? owner.access_token : ''}`} 
            strength={400}
          >
            <div 
              ref={(ref) => {insertRef && setLoadMoreRef(ref)}}
              style={{ height: (item.image_size.height * scale) * screenScale }}>
              <div className={classes.parallexContainer}
              >
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className={classes.parallexInfoContainer}>
                    <div className={classes.parallexTitleContainer}>
                      <p className={classes.parallexTitle} style={{width: `${windowSize.width - 180}px`, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{`${item.title}`}
                      {
                        windowSize.width >= Constant.ui.mobileDeviceWidth && 
                        `\u00A0- ${t('parallex.taken_on')} ${moment(item.taken_on).format('DD MMM yyyy')}`
                      }
                      </p>
                    </div>
                    {
                      Helper.stringHasValue(item.description) && windowSize.width >= Constant.ui.mobileDeviceWidth &&
                      <div className={classes.parallexDescription}>
                        {item.description}
                      </div>
                    }
                  </div>
                  <div className={classes.parallexActionContainer}>
                    <IconButton style={{color: '#ddd'}} onClick={() => {
                      setSelectedImageId(item.id);
                      assetViewCountInc(item.id);
                    }}>
                      <MUIIcons.Camera />
                    </IconButton>
                    <IconButton style={{color: '#ddd'}} aria-describedby={item.id} onClick={(event) => {
                      setShowShareOption(item.id);
                      setAnchorPopoverEl(event.currentTarget);
                    }}>
                      <MUIIcons.Share />
                    </IconButton>
                    <IconButton style={{color: '#ddd'}} onClick={() => {setItemToAdd(item.id)}}>
                      <MUIIcons.ShoppingBasket />
                    </IconButton>
                  </div>
                </div>
                
              </div>
            </div>
          </Parallax>
        );
      })
    }

    // If user is not searching and slideList have items
    if(!Helper.stringHasValue(searchKeyword) && Helper.arrayHasItem(slideList)) {
      let sortedSlide = slideList.sort((a, b) => (a.sort > b.sort) ? 1 : -1);
      sortedSlide.forEach(item => {
        const locIdx = Helper.isNotNullAndUndefined(item.sort) ? Number.parseInt(item.sort) : 0;
        const slide = <Parallax 
          key={item.id}
          bgImage={backgroundSlideImage} 
          strength={400}
        >
          <div style={{ minHeight: windowSize.height * 0.9 }}>
            <div className={classes.parallexContainer} style={{height: '100%'}}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className={classes.parallexInfoContainer}>
                  <div className={classes.parallexSlideTitleContainer} style={{justifyContent: 'center'}}>
                    <h1>{item.title}</h1>
                  </div>
                </div>
              </div>
              <Markdown style={{marginTop: 10, whiteSpace: 'pre-wrap', textAlign: 'center'}}>
                {item.content}
              </Markdown>
            </div>
          </div>
        </Parallax>;

        if(locIdx < parallaxUI.length) {
          parallaxUI.splice(locIdx, 0, slide);
        }
        else {
          parallaxUI.push(slide);
        }
      });
    }

    return parallaxUI;
  }

  const populateGridView = () => {

    if(!Helper.arrayHasItem(showCasePhoto))
      return null;

    const imageList = showCasePhoto.map((item, index) => {
      return {
        src: `${process.env.GATSBY_API_URL}/assets/view/${item._id}?token=${owner ? owner.access_token : ''}`,
        thumbnail: `${process.env.GATSBY_API_URL}/assets/thumbnail/${item._id}?token=${owner ? owner.access_token : ''}`,
        thumbnailWidth: item.image_size.width * 0.03,
        thumbnailHeight: item.image_size.height * 0.03,
        isSelected: false,
        caption: '',
        customOverlay: <div className={classes.gridViewThumbnailOverlay}>
          <span>{item.title}</span>
        </div>
      };
    });

    // console.log('Gallery imageList: ', imageList);
    let insertRef = false;
   
    // Only insert ref when total page is more then 1, 
    // and current page is not at last page
    insertRef = showCasePaging.total_page > 1 
                && showCasePaging.page < (showCasePaging.total_page - 1);

    return <div style={{display: 'flex', flexDirection: 'column'}}> 
      <Gallery 
        images={imageList}
        enableImageSelection={false}
        enableLightbox={false}
        rowHeight={300}
        onClickThumbnail={(idx, event) => {
          if(idx >= 0 && showCasePhoto.length > idx) {
            setSelectedImageId(showCasePhoto[idx].id);
            assetViewCountInc(showCasePhoto[idx].id);
          }
        }}
      />
      {
        insertRef &&
        <div ref={(ref) => {setLoadMoreRef(ref)}}></div>
      }
    </div>;
  }
  
  const setSelectedImageIdToNext = () => {
    if(Helper.stringHasValue(selectedImageId) && Helper.arrayHasItem(showCasePhoto) && showCasePhoto.length > 1) {
      let idx = showCasePhoto.findIndex(item => item.id === selectedImageId);
      if(idx >= 0 && idx < (showCasePhoto.length - 1)) {
        setSelectedImageId(showCasePhoto[idx + 1].id);
        assetViewCountInc(showCasePhoto[idx + 1].id);

        // If current page is less than total page
        // and current idx reached showCasePhoto.length - 3
        // and current is not in loading,
        // then load more photo
        if(showCasePaging.page < (showCasePaging.total_page - 1) && 
           idx >= (showCasePhoto.length - 3) &&
           !loadMoreInProgress) {
          loadShowCasePhotos();
        }
      }
    }
  }

  const setSelectedImageIdToPrev = () => {
    if(Helper.stringHasValue(selectedImageId) && Helper.arrayHasItem(showCasePhoto) && showCasePhoto.length > 1) {
      let idx = showCasePhoto.findIndex(item => item.id === selectedImageId);
      if(idx > 0) {
        setSelectedImageId(showCasePhoto[idx - 1].id);
        assetViewCountInc(showCasePhoto[idx - 1].id);
      }
    }
  }

  const imageViewerKeyPress = (event) => {
    // console.log('event.key: ', event.key);
    if([',', '<', '4'].includes(event.key)) {
      setSelectedImageIdToPrev();
    }

    if(['.', '>', '6'].includes(event.key)) {
      setSelectedImageIdToNext();
    }
  }

  const populateImageSize = (label, image) => {
    let imgSize = '';
    let localLabel = label || '';

    if(Helper.isNotNullAndUndefined(image)) {
      imgSize = `${localLabel} ${image.image_size.width}px X ${image.image_size.height}px`
    }
    
    return imgSize;
  }
  
  return (
    <Layout title={t('pageTitle')} authRequired={false} meta={pageMeta}>
      {/* <Seo title={t('pageTitle')} /> */}
      <div ref={topRef} className={commonClasses.formRow} style={{marginBottom: 10}}>
        <FormControl style={{display: 'flex', flex: 1}}>
          <InputLabel htmlFor="input-with-icon-adornment">{t('filter.header')}</InputLabel>
          <Input
            id="input-with-icon-adornment"
            value={searchKeyword}
            onChange={event => setSearchKeyword(event.target.value)}
            onBlur={_ => loadShowCasePhotos(true)}
            onKeyPress={(e) => {
              if(e.key === 'Enter') {
                loadShowCasePhotos(true);
              }
            }}
            endAdornment={
              <>
                {
                  Helper.stringHasValue(searchKeyword) &&
                  <InputAdornment position="end">
                    <IconButton onClick={() => {
                      setSearchKeyword('');
                      // Use settimeout in order to wait for the setSearchKeyword to complete.
                      setLoadMoreInProgress(true);
                      props.dispatch(
                        galleryActions.showCasePhotoGetList({keyword: {value: ''}}, 0, (success, result) => {
                          setLastSearchKeyword('');
                          setLoadMoreInProgress(false);
                        })
                      );
                      
                    }}>
                      <MUIIcons.Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
                <InputAdornment position="end">
                  <MUIIcons.Search />
                </InputAdornment>
              </>
            }
          />
        </FormControl>
        <IconButton onClick={() => {
          props.dispatch(
            pageActions.pageHomeSetViewMode(viewMode === 'PARALLAX' ? 'GRID' : 'PARALLAX')
          );
        }}>
          {
            viewMode !== 'PARALLAX' ?
            <MUIIcons.Collections />
            :
            <MUIIcons.GridOn />
          }
        </IconButton>
      </div>
      { 
        viewMode === 'PARALLAX' ?
        populateParallax()
        :
        populateGridView()
      }
      {
        !Helper.arrayHasItem(showCasePhoto) && Helper.stringHasValue(searchKeyword) &&
        <div style={{display: 'flex', flexDirection: 'row', height: 100, justifyContent: 'center', alignItems: 'center'}}>
          {t('gallery.noresult')}
        </div>
      }

      {
        showCasePhotoLoading &&
        <LinearProgress style={{marginTop: 20, marginBottom: 20}} /> 
      }
      {
        !topRefShowed &&
        <Fab color="primary" aria-label="top" 
          style={{position: 'fixed', right: 30, bottom: 30}}
          onClick={_ => {
            window.scrollTo({
              top: 0, 
              behavior: 'smooth'
            });
          }}
        >
          <MUIIcons.ArrowUpward />
        </Fab>
      }
      {/* Image Viewer Dialog */}
      <Dialog
        classes={{
          paper: dialogClasses.imageViewerContainer
        }}
        open={Helper.stringHasValue(selectedImageId)}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {setSelectedImageId('')}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onKeyPress={(event) => imageViewerKeyPress(event)}
      >
        <DialogContent className={classes.imageViewerContent}>
          {
            Helper.stringHasValue(selectedImageId) &&
            <TransformWrapper wheel={{step: 0.04}} initialScale={1}>
              {
                ({ zoomIn, zoomOut, resetTransform, ...rest }) => {
                  
                  return (
                    <React.Fragment>
                      <TransformComponent contentClass={dialogClasses.imageViewerTransformContent}>
                        <img 
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                          }} 
                          src={`${process.env.GATSBY_API_URL}/assets/view/${selectedImageId}?token=${owner ? owner.access_token : ''}`} alt="" />
                      </TransformComponent>
                      <div className={classes.imageViewerTransformToolbar}>
                        <div className={classes.row}>
                          <div className={classes.imageViewerTransformToolbarLeft}>
                            <IconButton style={{color: '#fff'}} onClick={() => setSelectedImageId('')}>
                              <MUIIcons.Close />
                            </IconButton>
                          </div>
                          <div className={classes.imageViewerTransformToolbarCenter}>
                            <IconButton style={{color: '#fff'}} className={classes.imageViewerTransformToolbarButton} onClick={() => setSelectedImageIdToPrev()}>
                              <MUIIcons.ChevronLeft />
                            </IconButton>
                            <IconButton style={{color: '#fff'}} className={classes.imageViewerTransformToolbarButton} onClick={() => zoomIn()}>
                              <MUIIcons.ZoomIn />
                            </IconButton>
                            <IconButton style={{color: '#fff'}} className={classes.imageViewerTransformToolbarButton} onClick={() => zoomOut()}>
                              <MUIIcons.ZoomOut />
                            </IconButton>
                            <IconButton style={{color: '#fff'}} className={classes.imageViewerTransformToolbarButton} onClick={() => resetTransform()}>
                              <MUIIcons.FullscreenExit />
                            </IconButton>
                            <IconButton style={{color: '#fff'}} className={classes.imageViewerTransformToolbarButton} aria-describedby={selectedImageId} onClick={(event) => {
                              setShowShareOption(selectedImageId);
                              setAnchorPopoverEl(event.currentTarget);
                            }}>
                              <MUIIcons.Share />
                            </IconButton>
                            <IconButton style={{color: '#fff'}} className={classes.imageViewerTransformToolbarButton} onClick={(event) => {
                              setItemToAdd(selectedImageId);
                            }}>
                              <MUIIcons.ShoppingBasket />
                            </IconButton>
                            <IconButton style={{color: '#fff'}} className={classes.imageViewerTransformToolbarButton} onClick={() => setSelectedImageIdToNext()}>
                              <MUIIcons.ChevronRight />
                            </IconButton>
                          </div>
                          <div className={classes.imageViewerTransformToolbarRight}>
                            <IconButton style={{color: '#fff'}} onClick={() => setExpandDetail(!expandDetail)}>
                              {
                                expandDetail ?
                                <MUIIcons.ExpandLess />
                                :
                                <MUIIcons.ExpandMore />
                              }
                            </IconButton>
                          </div>
                        </div>
                        <Collapse 
                          className={expandDetail ? classes.imageViewerPhotoDetailContainerExpanded : classes.imageViewerPhotoDetailContainerCollapsed}
                          in={expandDetail}
                        >
                          <div className={classes.column}>
                            <div className={classes.row}>
                              <span style={{
                                fontSize: 16, 
                                fontWeight: 'bold'}}
                              >
                                {Helper.carefullyGetValue(selectedImage, 'title'.split('.'), '')}
                              </span>
                              <span>
                                {`\u00A0- ${t('imageViewer.taken_on')} ${moment(Helper.carefullyGetValue(selectedImage, 'taken_on'.split('.'), '')).format('DD MMM yyyy')}`}
                              </span>
                            </div>
                            {
                              Helper.carefullyGetValue(selectedImage, 'description'.split('.'), '').length > 0 &&
                              <div className={classes.row}>
                                {Helper.carefullyGetValue(selectedImage, 'description'.split('.'), '')}
                              </div>
                            }
                            <div className={classes.row} style={{paddingTop: 10, flexWrap: 'wrap'}}>
                              {
                                selectedImage && selectedImage.image_size &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.image_size')} value={`${selectedImage.image_size.width}px X ${selectedImage.image_size.height}px`} />
                              }
                              {
                                selectedImage && selectedImageFormat &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.imageFormat')} value={selectedImageFormat.text} />
                              }
                              {
                                selectedImage && selectedImageMedium &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.imageMedium')} value={selectedImageMedium.text} />
                              }
                              {
                                selectedImage && selectedImage.camera_setting && Helper.carefullyGetValue(selectedImage, 'camera_setting.model'.split('.'), '').length > 0 &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.camera_setting.model')} value={Helper.carefullyGetValue(selectedImage, 'camera_setting.model'.split('.'), '')} />
                              }
                              {
                                selectedImage && selectedImage.camera_setting && Helper.carefullyGetValue(selectedImage, 'camera_setting.focal_length'.split('.'), '').length > 0 &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.camera_setting.focal_length')} value={Helper.carefullyGetValue(selectedImage, 'camera_setting.focal_length'.split('.'), '')} />
                              }
                              {
                                selectedImage && selectedImage.camera_setting && Helper.carefullyGetValue(selectedImage, 'camera_setting.shutter_speed'.split('.'), '').length > 0 &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.camera_setting.shutter_speed')} value={Helper.carefullyGetValue(selectedImage, 'camera_setting.shutter_speed'.split('.'), '')} />
                              }
                              {
                                selectedImage && selectedImage.camera_setting && Helper.carefullyGetValue(selectedImage, 'camera_setting.aperture'.split('.'), '').length > 0 &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.camera_setting.aperture')} value={Helper.carefullyGetValue(selectedImage, 'camera_setting.aperture'.split('.'), '')} />
                              }
                              {
                                selectedImage && selectedImage.camera_setting && Helper.carefullyGetValue(selectedImage, 'camera_setting.iso'.split('.'), '').length > 0 &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.camera_setting.iso')} value={Helper.carefullyGetValue(selectedImage, 'camera_setting.iso'.split('.'), '')} />
                              }
                              {
                                selectedImage && selectedImage.location && Helper.carefullyGetValue(selectedImage, 'location.name'.split('.'), '').length > 0 &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.location')} value={Helper.carefullyGetValue(selectedImage, 'location.name'.split('.'), '')} />
                              }
                              {
                                selectedImage && selectedImage.tag && Helper.arrayHasItem(selectedImage.tag) &&
                                <FieldValue containerClassName={classes.imageViewerPhotoProps} label={t('imageViewer.tag')} value={selectedImage.tag.map(item => `#${item}\u00A0\u00A0`).join('')} />
                              }
                            </div>
                            {
                              windowSize.width >= Constant.ui.mobileDeviceWidth &&
                              <div className={classes.row} style={{paddingTop: 10, flexWrap: 'wrap'}}>
                                {t('imageViewer.note')}
                              </div>
                            }
                          </div>
                        </Collapse>
                      </div>
                    </React.Fragment>
                  );
                }
              }
            </TransformWrapper>
          }
        </DialogContent>
      </Dialog>

      {/* Add to cart Dialog */}
      <Dialog
        classes={{
          paper: dialogClasses.cartDialogContainer
        }}
        open={Helper.stringHasValue(itemToAdd)}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onCartFormClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{t('dialog.addCartItem.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('dialog.addCartItem.desc')}
          </DialogContentText>
          <div className={classes.column}>
            <div className={classes.row}>
              {
                windowSize.width >= Constant.ui.mobileDeviceWidth &&
                <div className={classes.column} style={{flex: 1}}>
                  <Image
                    src={`${process.env.GATSBY_API_URL}/assets/view/${itemToAdd}?token=${owner ? owner.access_token : ''}`}
                    width={(windowSize.width * 0.5) / 3}
                  />
                </div>
              }
              <div className={classes.column} style={{flex: 2, padding: 4, paddingLeft: 10}}>
                <FormControl variant="outlined" error={itemToAddFormValidation.licenseType.error}>
                  <InputLabel id="imageLicenseType">{t('field.label.licenseType')}</InputLabel>
                  <Select
                    labelId="imageLicenseType"
                    id="imageFormat"
                    value={itemToAddForm.licenseType}
                    onChange={event => onFormFieldChanged('licenseType', event.target.value)}
                    onBlur={(event) => validateFormField('licenseType', event.target.value)}
                    label={t('field.label.licenseType')}
                  >
                    {
                      Helper.arrayHasItem(sysList) &&
                      sysList
                      .filter(item => item.category === 'LICENSE_TYPE')
                      .map(item => (
                        <MenuItem value={item.code} key={`LICENSE_TYPE_${item.code}`}>
                          {item.text}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText>{itemToAddFormValidation.licenseType.message}</FormHelperText>
                </FormControl>
                {
                  itemToAddForm.licenseType === 'WEB' &&
                  <TextField id="imageWebsite" label={t('field.label.website')} 
                    variant="outlined" value={itemToAddForm.website} 
                    style={{marginTop: 10}}
                    error={itemToAddFormValidation.website.error}
                    helperText={itemToAddFormValidation.website.message}
                    onChange={event => onFormFieldChanged('website', event.target.value)}
                    onBlur={(event) => validateFormField('website', event.target.value)}
                  />
                }
                {
                  itemToAddForm.licenseType === 'ADVERTISING' &&
                  <>
                    <TextField id="imageCoName" label={t('field.label.co.name')} 
                      variant="outlined" value={itemToAddForm.coName} 
                      style={{marginTop: 10}}
                      error={itemToAddFormValidation.coName.error}
                      helperText={itemToAddFormValidation.coName.message}
                      onChange={event => onFormFieldChanged('coName', event.target.value)}
                      onBlur={(event) => validateFormField('coName', event.target.value)}
                    />
                    <div className={classes.row} style={{gap: 10, flexWrap: 'wrap'}}>
                      <TextField id="imageCoRegNum" label={t('field.label.co.regNum')} 
                        variant="outlined" value={itemToAddForm.coRegNum} 
                        style={{marginTop: 10, flex: 1, minWidth: 250}}
                        error={itemToAddFormValidation.coRegNum.error}
                        helperText={itemToAddFormValidation.coRegNum.message}
                        onChange={event => onFormFieldChanged('coRegNum', event.target.value)}
                        onBlur={(event) => validateFormField('coRegNum', event.target.value)}
                      />
                      <TextField id="imageCoRegCountry" label={t('field.label.co.country')} 
                        variant="outlined" value={itemToAddForm.coRegCountry} 
                        style={{marginTop: 10, flex: 1, minWidth: 250}}
                        error={itemToAddFormValidation.coRegCountry.error}
                        helperText={itemToAddFormValidation.coRegCountry.message}
                        onChange={event => onFormFieldChanged('coRegCountry', event.target.value)}
                        onBlur={(event) => validateFormField('coRegCountry', event.target.value)}
                      />
                    </div>
                    <TextField id="imageCoWebsite" label={t('field.label.co.website')} 
                      variant="outlined" value={itemToAddForm.coWebsite} 
                      style={{marginTop: 10}}
                      error={itemToAddFormValidation.coWebsite.error}
                      helperText={itemToAddFormValidation.coWebsite.message}
                      onChange={event => onFormFieldChanged('coWebsite', event.target.value)}
                      onBlur={(event) => validateFormField('coWebsite', event.target.value)}
                    />
                  </>
                }
                {
                  Helper.stringHasValue(itemToAddForm.licenseType) &&
                  <FieldValue containerStyle={{minWidth: 100, margin: 0, marginTop: 10, minHeight: 60}} label={t('field.label.price')} value={getItemPrice()} />
                }
                {
                  Helper.isNotNullAndUndefined(licenseTypeContent) &&
                  <Markdown style={{marginTop: 10, whiteSpace: 'pre-wrap'}}>
                    {licenseTypeContent.content}
                  </Markdown>
                }
                {
                  Helper.stringHasValue(itemToAddForm.licenseType) &&
                  <FormControl variant="outlined" error={itemToAddFormValidation.ack.error}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={itemToAddForm.ack === '1'}
                          onChange={event => {
                            onFormFieldChanged('ack', event.target.checked ? '1' : '');
                            validateFormField('ack', event.target.checked ? '1' : '')
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={t('field.label.licenseAgreement.desc')}
                    />
                    <FormHelperText>{itemToAddFormValidation.ack.message}</FormHelperText>
                  </FormControl>
                }
              </div>
            </div>
            <div className={classes.row} style={{marginTop: 10}}>
              {populateImageSize(t('dialog.addCartItem.resolution'), selectedCartImage)}<br/>
              {t('dialog.addCartItem.note')}
            </div>
          </div>
        </DialogContent>
        <DialogActions 
          className={commonClasses.modalFooter} 
          style={{justifyContent: 'center'}}
        >
          <Button
            onClick={() => onCartFormClose()}
            variant='contained'
          >
            {t('button.cancel')}
          </Button>
          <Button
            color={'primary'}
            onClick={() => onCartFormAdd()}
            variant='contained'
          >
            {t('button.add')}
          </Button>
        </DialogActions> 
      </Dialog>

      {
        Helper.isNotNullAndUndefined(selectedShareImage) &&
        <Popover
          id={showShareOption}
          open={Helper.isNotNullAndUndefined(anchorPopoverEl)}
          anchorEl={anchorPopoverEl}
          onClose={() => setAnchorPopoverEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: classes.sharePopover
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <FacebookShareButton
              url={`${process.env.GATSBY_API_URL}/assets/social/${showShareOption}`}
              quote={`${selectedShareImage.title}\n${selectedShareImage.description}`}
              hashtag={Helper.arrayHasItem(selectedShareImage.tag) ? `#${selectedShareImage.tag[0]}` : '#JustShootIt'}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              url={`${process.env.GATSBY_API_URL}/assets/social/${showShareOption}`}
              title={`${selectedShareImage.title}`}
              via={'JustShootItSg'}
              hashtags={selectedShareImage.tag}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton
              url={`${process.env.GATSBY_API_URL}/assets/social/${showShareOption}`}
              separator={'\n'}
              title={t('share.whatapp.title').replace('{title}', selectedShareImage.title)}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </Popover>
      }
      <br /><br />
    </Layout>
  );
}

export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    page: state['page'],
    system: state['system'],
    gallery: state['gallery'],
    cart: state['cart']
  };
})(IndexPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
