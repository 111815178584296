import { useState, useEffect } from 'react'

const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);
    // console.log('useIntersection: ', element);
    useEffect(() => {
      const observer = new IntersectionObserver(
          ([entry]) => {
              setState(entry.isIntersecting);
          }, { rootMargin }
      );

      let el = element?.current ? element.current : element;

      el && observer.observe(el);

      return () => el && observer.unobserve(el);
    }, [element]);

    return isVisible;
};

export default useIntersection;